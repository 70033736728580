
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import 'tailwindcss/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bezier-curve-editor/index.css';
import '@/styles/app.css';
import React from 'react';
import Head from 'next/head';
import { RecoilRoot } from 'recoil';
import type { AppProps } from 'next/app';
import { Client, Provider, cacheExchange, fetchExchange } from 'urql';
import { ErrorBoundary } from '@/components/generic';

const client = new Client({
  url: '/api/graphql',
  exchanges: [cacheExchange, fetchExchange],
});

const __Page_Next_Translate__ = function App({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <Head>
        <title>Figmotion</title>
      </Head>
      <RecoilRoot>
        <Provider value={client}>
          <Component {...pageProps} />
        </Provider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  